import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModuleModule } from 'src/app/_shared_module/shared-module.module'
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { JwtInterceptor } from './herpers/jwt.interceptor';
import { ErrorInterceptor } from './herpers/error.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    ChangePasswordComponent
  ],
  imports: [
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    SharedModuleModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [ 
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
