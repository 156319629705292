<mat-toolbar>
    <img src="assets/img/logo.png" width="150" style="margin-left: 30px;">
    <span class="example-spacer"></span>
    <div style="color:white">
        <a href="javascript:" [ngClass]="{'menu-btn': true, 'btn-selected': isReport}" *ngIf="showButtons" [routerLink]="['/relatorios']">Relatórios</a>
        <a [matMenuTriggerFor]="menu" href="javascript:" [ngClass]="{'menu-btn': true, 'btn-selected': !isReport}" *ngIf="showButtons">{{userName}}
            <img src="assets/img/arrow_down.png" style="margin-left: 3px;">
        </a>
        <mat-menu #menu="matMenu" class="lkwd-menu">
            <button mat-menu-item class="lkwd-menu-item" [routerLink]="['/alterar-senha']">
                <img src="assets/img/menu_pass.png" style="margin-right: 10px;">
                <span>Alterar senha</span>
            </button>
            <button mat-menu-item class="lkwd-menu-item"  [routerLink]="['/termos-e-politica']">
                <img src="assets/img/menu_terms.png" style="margin-right: 10px;">
                <span>Termos e politica</span>
            </button>
            <button mat-menu-item class="lkwd-menu-item" (click)="onLogout()">
                <img src="assets/img/menu_loggout.png" style="margin-right: 10px;">
                <span>Logout</span>
            </button>
        </mat-menu>
    </div>
</mat-toolbar>
<div class="wrapper">
    <ng-content>
    </ng-content>
</div>
<div class="footer">
    <span style="float: left;margin-top: 30px;margin-left: 60px;opacity: 87%;font-family: 'lkwd-sf-regular' !important">Lakewood 2020. Todos os direitos reservados.</span>
</div>
