import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'lkwd-input-filter',
  templateUrl: './lkwd-input-filter.component.html'
})
export class LkwdInputFilterComponent implements OnInit {

  @Input() filterList:string[] = [];
  @Input() label = '';
  @Input() model:string = '';
  @Input() placeholder:string = '';
  @Output() modelChange:EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    //distinctUntilChanged(),
    map(term => term.length < 2 ? this.filterList: 
      this.filterList.filter(v => v.normalize("NFD").toLowerCase()
      .indexOf(term.normalize("NFD").toLowerCase()) > -1)
      .slice(0, 10))
  );

  onInputFocus(e: Event) {
    this.model = '';
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e?.target?.dispatchEvent(inputEvent);
    }, 0);   
  }

  onModelChanged(name:string) {
    this.modelChange.emit(name);
  }

  ngOnInit(): void {}

}
