import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { FirstAccess } from '../models/first-access';

@Injectable({ providedIn: 'root' })
export class OnboardingService {

  private headerOptions = { headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'X-API-KEY': 'YvGC84TnCTSyo6N27AkoW3VM5QxBXg7RmVMWbXj0' }) };
    
  private onboardingSubject: BehaviorSubject<any>;
  public onboarding: Observable<any>;

  private firstAccessSubject: BehaviorSubject<FirstAccess>;
  public firstAccessObservable: Observable<FirstAccess>;

  constructor(private http: HttpClient) {
    this.onboardingSubject = new BehaviorSubject<FirstAccess>(null as any);
    this.firstAccessSubject = new BehaviorSubject<FirstAccess>(null as any);
   }

  resetPassword(email: string) {
    return this.http
    .post<any>(environment.apiUrl + '/user/reset-password', { email })
    .pipe(map(resp => {
        this.onboardingSubject.next(resp);
        return resp;
      }));
  }

  passwordToken(token: string) {
    return this.http
    .get<any>(environment.apiUrl + '/user/reset-password-token', { params: new HttpParams({fromObject : { token }}) })
    .pipe(map(resp => {
      this.onboardingSubject.next(resp);
      return resp;
    }));
  }

  setPasswordWithToken(token: string, password:string) {
    return this.http
    .post<any>(environment.apiUrl + '/user/set-password-with-token', { token, password })
    .pipe(map(resp => {
      this.onboardingSubject.next(resp);
      return resp;
    }));
  }

  changePassword(email:string, oldpassword:string, newpassword:string) {
    return this.http
    .post<any>(environment.apiUrl + '/client/change-password', { email, oldpassword, newpassword })
    .pipe(map(resp => {
      this.onboardingSubject.next(resp);
      return resp;
    }));
  }

  firstAccess(code: string) {
    return this.http
    .post<FirstAccess>(environment.apiUrl + '/onboarding/firstaccess', { code })
    .pipe(map(resp => {
      this.firstAccessSubject.next(resp);
      return resp;
    }));
  }

  setPassword(code: string, password: string) {
    return this.http
    .post<any>(environment.apiUrl + '/onboarding/setpassword', { code, password })
    .pipe(map(resp => {
      this.onboardingSubject.next(resp);
      return resp;
    }));
  }

  //resendcode
  resendCode(email: string) {
    return this.http
    .post<any>(environment.apiUrl + '/onboarding/resendcode', { email })
    .pipe(map(resp => {
      this.onboardingSubject.next(resp);
      return resp;
    }));
  }
}
