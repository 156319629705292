import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import { MatIconModule } from "@angular/material/icon";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LkwdButtonComponent } from './components/lkwd-button/lkwd-button.component';
import { LkwdLayoutComponent } from './components/lkwd-layout/lkwd-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';

import {MatMenuModule} from '@angular/material/menu';
import { LkwdPassInputComponent } from './components/lkwd-pass-input/lkwd-pass-input.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LkwdInputFilterComponent } from './components/lkwd-input-filter/lkwd-input-filter.component';
import { NgxLoadingModule } from 'ngx-loading';
import { LkwdLoadingComponent } from './components/lkwd-loading/lkwd-loading.component';
import { LkwdModalComponent } from './components/modals/lkwd-modal/lkwd-modal.component';
import { LkwdNotificationComponent } from './components/modals/lkwd-notification/lkwd-notification.component';



@NgModule({
  declarations: [
    LkwdButtonComponent,
    LkwdLayoutComponent,
    LkwdPassInputComponent,
    LkwdInputFilterComponent,
    LkwdLoadingComponent,
    LkwdModalComponent,
    LkwdNotificationComponent
  ],
  imports: [    
    NgxLoadingModule.forRoot({}),
    MatDialogModule,
    NgxExtendedPdfViewerModule,
    NgbModule,
    MatMenuModule,
    MatToolbarModule,
    RouterModule,
    MatIconModule,
    MatChipsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    LkwdButtonComponent,
    LkwdLayoutComponent,
    LkwdPassInputComponent,
    LkwdInputFilterComponent,
    LkwdLoadingComponent,
    LkwdModalComponent
  ]
})
export class SharedModuleModule { }
