import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'lkwd-pass-input',
  templateUrl: './lkwd-pass-input.component.html',
  styles: [`

  .lkwd-pass-medium {
    height: 53px !important;
  }

  .lkwd-pass-btn {
    background-color: #001d2b; 
    height: 100%;
    opacity: 70%;
    border: 'none';
    border-radius: 0;
  }

  button {
    outline: none;
    border: #001d2b !important;
  }

`]
})
export class LkwdPassInputComponent implements OnInit {

  @Input() hidePassword = true;
  @Input() inputLabel = '';
  @Output() passwordChanged:EventEmitter<string> = new EventEmitter<string>();
  @Output() value:string = '';
  @Input() submitted = false;
  @Output() get hasErrors() {
    return this.form.controls['password'].errors
  }

  public model:string = "";
  form: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: ['', [
        Validators.required, 
        Validators.pattern(/^(?=.*[A-Z])(?=.*[!@_#$&%*-])(?=.*[0-9])(?=.*[a-z]).{8,}$/)]]
    });
  }
  get f() { 
    return this.form.controls;
  }
}
