<div class="fluid-container">
    <div class="d-flex justify-content-center">
        <p class="title-label">Alterar senha</p>
    </div>
    <div class="d-flex justify-content-center mt-3 mb-4" *ngIf="isReset">
        <div class="form-group" style="width: 397px;">
            <input #email name="lkwd-field-email" type="text" class="form-control lakewood-input" id="lkwd-field-email" [value]="userEmail" readonly>         
        </div>
    </div>
    <div class="d-flex justify-content-center mt-3">
        <lkwd-pass-input inputLabel="Nova senha" style="width: 397px;" #password [submitted]="submitted"></lkwd-pass-input>
    </div>
    <div class="d-flex justify-content-center mt-3">
        <lkwd-pass-input [inputLabel]="isReset ? 'Confirmação' : 'Senha Atual'" style="width: 397px;" #password_confirm></lkwd-pass-input>
    </div>
    <div class="d-flex justify-content-center">
        <div *ngIf="displayError" class="error-feedback">
            <span>{{ errorMessage }}</span>
        </div>
    </div>
    <div class="d-flex justify-content-center mt-3">
        <lkwd-button text="Continuar" 
            [isLarge]="true" 
            (btnClick)="onChangePassword(password, password_confirm)">
        </lkwd-button>
    </div>
</div>