import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModalBase } from 'src/app/models/modals/modal-base';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private subject = new Subject<ModalBase>();
  private closeSubject = new Subject<ModalBase>();
  constructor() { }

  // enable subscribing to alerts observable
  onShowModal(id = 'default-modal'): Observable<ModalBase> {
    return this.subject.asObservable();
  }

  onCloseModal(id = 'default-modal'): Observable<ModalBase> {
    return this.closeSubject.asObservable().pipe(filter(x => x && x.id === id));
  }

  // main alert method    
  showModal(modal: ModalBase) {
    this.subject.next(modal);
  }

  // main alert method    
  closeModal(modal: ModalBase) {
    this.closeSubject.next(modal);
  }
}
