import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'lkwd-layout',
  styleUrls: ['./lkwd-layout.component.css'],
  templateUrl: './lkwd-layout.component.html'
})
export class LkwdLayoutComponent implements OnInit {

  public userName = '';
  public showButtons = false;
  constructor(private loginService:LoginService, private router:Router) { }

  public isReport = false;

  ngOnInit(): void {

    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        if(val.url.includes('relatorios')) {
          this.isReport = true;
        } else {
          this.isReport = false;
        }      
      }
    });

    this.loginService.user
    .subscribe(_user => {      
      if(_user != null && Object.entries(_user).length !== 0 && _user.user.userTermsAccepted) {
        this.userName = _user.user.name;
        this.showButtons = true;
      } 
      else {
        this.showButtons = false;
        this.userName = '';
      }
    });

    if (this.loginService.userValue != null && Object.entries(this.loginService.userValue).length !== 0) {
      this.router.navigate([this.loginService.userValue.user.userTermsAccepted ? 
        '/relatorios' : '/login/aceitar-termos']);
    }
  }

  onLogout() {
    this.loginService.logout();
  }

}
