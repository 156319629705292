import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ModalBase } from 'src/app/models/modals/modal-base';
import { Modal } from 'src/app/models/modals/modal';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-lkwd-modal',
  templateUrl: './lkwd-modal.component.html',
  styleUrls: ['./lkwd-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LkwdModalComponent implements OnInit, OnDestroy {

  public isUpload:boolean = false;
  modalImage = '';
  modals: ModalBase[] = [];
  currentModal: ModalBase;

  @Input() id = 'default-modal';  
  showModalSubscription: Subscription;

  @ViewChild('defaultModal')
  private defaultModal: TemplateRef<any>;

  constructor(private modalService: NgbModal, private lkwdModalService: ModalService) { }    

  ngOnInit(): void {
    this.showModalSubscription = this.lkwdModalService.onShowModal(this.id)
    .subscribe(modal => {
        this.currentModal = modal;
        if (modal instanceof Modal) {
          this.open(this.defaultModal);
        } 
        this.modals.push(modal); 
    });
  }

  ngOnDestroy() {
    this.showModalSubscription.unsubscribe();
  }

  open(content:any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  onCloseModal() {
    this.modalService.dismissAll();
  }
}
