import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading.service';
import { LoginService } from 'src/app/services/login.service';
import { TermsService } from 'src/app/services/terms.service';

@Component({
  selector: 'app-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.css']
})
export class AcceptTermsComponent implements OnInit {
  
  public showFull:boolean;
  public content:string = '';

  public termsAccepted:boolean = false;
  public model:boolean = false;
  constructor(private router:Router, 
    private loadingService:LoadingService, 
    private termsService:TermsService,
    private loginService:LoginService) { }

  ngOnInit(): void {
    this.loadingService.setLoading();
    
    this.termsService.terms()
    .pipe(first())
    .subscribe({
      next: (_terms) => {
        this.content = _terms.terms;

        this.loadingService.setLoading(false);
      },
      error: error => {
        this.loadingService.setLoading(false);
      }
    });
    this.showFull = !this.router.url.includes('login');
  }

  onAcceptTerms() {
    this.loadingService.setLoading();
    this.termsService.accept()
    .subscribe({
      next: (_resp) => {
        this.loadingService.setLoading(false);
        this.loginService.acceptTerms();
        this.router.navigateByUrl('relatorios');
      },
      error: error => {
        this.loadingService.setLoading(false);
      }
    });
  }
}
