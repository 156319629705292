import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthGuard } from './herpers/auth.guard'
import { AcceptTermsComponent } from './modules/login/components/accept-terms/accept-terms.component';

const LoginModule = () => import('./modules/login/login.module').then(x => x.LoginModule);
const ReportsModule = () => import('./modules/reports/reports.module').then(x => x.ReportsModule);

const routes: Routes = [
  { path: 'login', loadChildren: LoginModule},
  { path: 'relatorios', loadChildren: ReportsModule, canActivate: [AuthGuard]},
  { path: 'termos-e-politica', component: AcceptTermsComponent, canActivate: [AuthGuard] },
  { path: 'alterar-senha', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'resetar-senha/:token', component: ChangePasswordComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
