import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Modal } from '../models/modals/modal';
import { ModalImageType } from '../models/modals/modal-base';
import { LoadingService } from '../services/loading.service';
import { LoginService } from '../services/login.service';
import { ModalService } from '../services/modal.service';
import { OnboardingService } from '../services/onboarding.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  public token:string;
  userEmail = '';
  isReset:boolean = false;
  submitted:boolean = false;

  currentPassword:any;

  displayError:boolean = false;
  errorMessage:string = '';

  constructor(
    private route:ActivatedRoute, 
    private router: Router,
    private onboardingService: OnboardingService,
    private loadingService:LoadingService,
    private modalService:ModalService,
    private loginService:LoginService) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.params['token'];
    if(this.token != undefined && this.router.url.includes('resetar')) {
      this.isReset = true;
      this.loadingService.setLoading();

      this.onboardingService.passwordToken(this.token)
      .pipe(first())
      .subscribe({
          next: (_resp) => {
            this.userEmail = _resp.email;
            this.loadingService.setLoading(false);
          },
          error: error => {
            if(error.error == 'passwordtoken_invalid') {
              this.modalService.showModal(
                new Modal (
                  'Token inválido', 
                  'Token com validade expirada!',   
                  ModalImageType.error));
            }

            if(error.error == 'passwordtoken_not_registered') {
              this.modalService.showModal(
                new Modal (
                  'Token inválido', 
                  'Token não registrado!',   
                  ModalImageType.error));
            }
            this.loadingService.setLoading(false);

            this.router.navigate(['/login']);
          }
      });
    } else {
      this.userEmail = this.loginService?.userValue?.user?.email || '';

      if(this.userEmail == '') {
        this.router.navigate(['/login']);
      }
    }  
  }

  onChangePassword(password1:any, password2:any) {
    this.submitted = true;

    if(password1.hasErrors) {
      return;
    }

    if(this.isReset) {
      if(password1.value != password2.value) {
        this.displayError = true;
        this.errorMessage = 'Senhas digitadas não conferem'
      } 
      else {
        this.displayError = false;
  
        this.loadingService.setLoading();
  
        this.onboardingService.setPasswordWithToken(this.token, password1.value)
        .pipe(first())
        .subscribe({
            next: (_resp) => {
              this.userEmail = _resp.email;
              this.loadingService.setLoading(false);
  
              this.modalService.showModal(
                new Modal (
                  'Senha alterada', 
                  'Senha alterada com sucesso!',   
                  ModalImageType.success));
  
              this.router.navigate(['/login']);
            },
            error: error => {
              this.loadingService.setLoading(false);
  
              this.router.navigate(['/login']);
            }
        });
      }
    } else {
      this.displayError = false;
  
      this.loadingService.setLoading();

      this.onboardingService.changePassword(this.userEmail, password2.value,  password1.value)
      .pipe(first())
      .subscribe({
          next: (_resp) => {
            this.loadingService.setLoading(false);

            this.modalService.showModal(
              new Modal (
                'Senha alterada', 
                'Senha alterada com sucesso!',   
                ModalImageType.success));

            this.router.navigate(['/relatorios']);
          },
          error: error => {
            this.modalService.showModal(
            new Modal (
              'Erro ao alterar a senha', 
              error.message,   
              ModalImageType.warning));

            this.loadingService.setLoading(false);

            this.router.navigate(['/relatorios']);
          }
      });
    }
  }
}
