import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoggedUser } from '../models/logged-user';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root'})
export class LoginService {

  private userSubject: BehaviorSubject<LoggedUser>;
  public user: Observable<LoggedUser>;
  
  constructor(private router: Router, private http: HttpClient) {
    this.userSubject = new BehaviorSubject<LoggedUser>(JSON.parse(localStorage.getItem('user') || '{}'));
    this.user = this.userSubject.asObservable();
  }

   public get userValue(): LoggedUser {
    return this.userSubject.value;
  }

  login(email:string, password:string) {
    return this.http.post<LoggedUser>(environment.apiUrl + '/login/authenticate', { email, password })
        .pipe(map(user => {
            localStorage.setItem('user', JSON.stringify(user));
            this.userSubject.next(user);
            return user;
        }));
  }

  acceptTerms() {
    this.userValue.user.userTermsAccepted = true;
    this.userSubject.next(this.userValue);
  }

  logout() {
    localStorage.removeItem('user');
    this.userSubject.next(null as any);
    this.router.navigate(['/']);
  }
}
