
    <div class="d-flex justify-content-center">
        <p class="title-label">Termos e politica de privacidade</p>
    </div>
    <div class="d-flex justify-content-center mt-3">
        <textarea  rows="1" [ngClass]="{
            'container-fluid': true, 
            'text-filed': !showFull, 
            'text-filed-long': showFull}" readonly>{{content}}</textarea>
    </div>
  

    <div class="d-flex justify-content-center mt-5" *ngIf="!showFull">
        <span style="color: white;margin-right: 10px;opacity: 87%;">Li e aceito os termos e políticas da Lakewood.</span>
        <mat-checkbox 
            [(ngModel)]="termsAccepted">
        </mat-checkbox>
    </div>

    <div class="d-flex justify-content-center mt-5" *ngIf="!showFull">
        <lkwd-button text="Continuar" 
            [isLarge]="true" 
            (btnClick)="onAcceptTerms()"
            [disabled]="!termsAccepted">
        </lkwd-button> 
    </div>

