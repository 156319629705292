import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'lkwd-button',
  templateUrl: './lkwd-button.component.html',
  styles: 
  [
    `
    .lkwd-large-btn {
      width: 397px;
      height: 50px;
      padding: 5px 3px;
      outline: none;
      font-weight: bold;
    }

    .lkwd-medium-btn {
      width: 190px;
      padding: 5px 3px;
      outline: none;
      font-weight: bold;
    }

    .lkwd-small-btn {
      width: 126px;
      height: 35px;
      margin-top: 4px;
      padding: 0px;
      outline: none;
      font-weight: bold;
    }

    .lkwd-light-btn{
      background-color: #00AF97;
      color: #001d2b;
    }
    
    .lkwd-dark-btn{
      background-color: transparent;
      border: 1px solid #00AF97 !important;
      color: #00AF97;
    }

    .lkwd-remove-btn {
      background-color: transparent;
      border: 1px solid #E46C6C !important;
      color:  #E46C6C;
      font-weight: regular;
    }
    `
  ]
})
export class LkwdButtonComponent implements OnInit {

  @Input() text:string = '';
  @Input() isDark:boolean = false;
  @Input() isRemove:boolean = false;
  @Input() isSmall:boolean = false;
  @Input() isLarge:boolean = false;
  @Output() btnClick:EventEmitter<any> = new EventEmitter();
  @Input() disabled = false;

  isMedium = false;
  
  constructor(private breakpointObserver: BreakpointObserver) { }

  clicked() { this.btnClick.emit(); }

  ngOnInit(): void {
    this.breakpointObserver.observe(Breakpoints.HandsetPortrait)
    .pipe(map(result => result.matches), shareReplay())
    .subscribe(
      isHandset => {
        if(isHandset) {
          this.isSmall = false;
          this.isLarge = false;
        }
      },
      error => {
      }
    );
  }
}
